<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
      <b-row>
        <b-col cols="12">
          <validation-provider name="Bölge Adı" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Bölge Adı">
              <b-form-input
                class="rounded-0"
                ref="baslik"
                v-model="form.baslik"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />

              <b-form-invalid-feedback id="input-1-live-feedback">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Anasayfa Gösterim">
            <v-select
              v-model="form.anasayfa"
              :options="AnasayfaStatu"
              :reduce="(ana) => ana.value"
              label="title"
              :clearable="false"
              class="invoice-filter-select d-block"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Görsel">
            <b-form-file
              v-model="gorsel"
              placeholder="Bir dosya seçin veya buraya bırakın..."
              drop-placeholder="Drop file here..."
              @change="onBannerFileChange"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-img v-if="newBannerSelect" thumbnail fluid rounded :src="banner_url" />
          <div v-else-if="form.gorsel" class="grid">
            <b-img thumbnail fluid rounded :src="imageBannerFile" />
            <div class="figcaption">
              <feather-icon
                class="text-danger cursor-pointer"
                size="24"
                icon="Trash2Icon"
                v-b-tooltip.hover.top="'Görsel Sil!'"
                @click="handlerBannerRemove(form.k_no)"
              />
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between align-items-center py-1">
            <label for="statu" class="font-weight-bold h6">Kullanım Durumu</label>
            <b-form-checkbox :checked="form.statu" v-model="form.statu" switch />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button squared block type="submit" :variant="form._id == null ? 'primary' : 'warning'">
            <feather-icon icon="CheckSquareIcon" class="mr-50" />
            <span class="align-middle">{{ form._id == null ? 'Ekle' : 'Güncelle' }}</span>
          </b-button>
          <b-button squared block type="submit" v-if="form._id" @click="resetForm">
            <feather-icon icon="CheckSquareIcon" class="mr-50" />
            <span class="align-middle">iptal</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
import { ref, toRefs, watch, computed } from '@vue/composition-api';
import store from '@/store';
import { useToast } from 'vue-toastification/composition';
import { POSITION } from 'vue-toastification';
export default {
  components: {
    vSelect,
  },
  props: {
    bolgeler: {
      type: Array,
      required: true,
    },
    updateData: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.show = ref(false);
    expo.AnasayfaStatu = ref([
      {
        value: false,
        title: 'Hayır',
      },
      {
        value: true,
        title: 'Evet',
      },
    ]);
    expo.banner_url = ref(null);
    expo.newBannerSelect = ref(false);
    expo.gorsel = ref(null);
    expo.form = ref({
      _id: null,
      k_no: null,
      baslik: null,
      gorsel: null,
      anasayfa: false,
      statu: true,
    });

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.resetForm = () => {
      expo.form.value = {
        _id: null,
        k_no: null,
        baslik: null,
        gorsel: null,
        anasayfa: false,
        statu: true,
      };
    };

    expo.onBannerFileChange = (e) => {
      const file = e.target.files[0];
      expo.banner_url.value = URL.createObjectURL(file);
      expo.newBannerSelect.value = true;
    };

    expo.imageBannerFile = computed(() => {
      console.log(expo.form.value);
      const base_url =
        process.env.NODE_ENV === 'development'
          ? process.env.VUE_APP_DEV_BASEURL.slice(0, -3)
          : process.env.VUE_APP_PRO_BASEURL.slice(0, -3);
      if (expo.form.value.gorsel == null) {
        return '';
      } else {
        return `${base_url}uploads/bolge/large/${expo.form.value.gorsel}`;
      }
    });

    expo.onSubmit = () => {
      expo.show.value = true;
      let formData = new FormData();
      let forms = expo.form.value;
      Object.keys(forms).map(function (key) {
        if (key == 'icerik') {
          formData.append(key, JSON.stringify(forms[key]));
        } else {
          formData.append(key, forms[key]);
        }
      });
      if (expo.gorsel.value != null) {
        formData.append('bolge', expo.gorsel.value);
      }
      const basrUrl = expo.form.value._id == null ? 'bolgeEkle' : 'bolgeGuncelle';
      store
        .dispatch(basrUrl, formData)
        .then((res) => {
          if (res.data.success == true) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: expo.form.value._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
            context.emit('refresh', res.data.data);
            if (expo.form.value._id == null) {
              // expo.resetForm();
            }
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast(
              {
                component: ToastificationContent,
                props: {
                  title: `Bilgi`,
                  icon: 'CheckSquareIcon',
                  variant: 'success',
                  text: err.message,
                },
              },
              { position: POSITION.BOTTOM_RIGHT }
            );
          }
        });
    };

    expo.handlerBannerRemove = (k_no) => {
      store.dispatch('bolgeGorselSil', k_no).then((res) => {
        if (res.data.success == true) {
          toast(
            {
              component: ToastificationContent,
              props: {
                title: `Bilgi`,
                icon: 'CheckSquareIcon',
                variant: 'danger',
                text: 'Görsel Silme Başarılı.',
              },
            },
            { position: POSITION.BOTTOM_RIGHT }
          );
          context.emit('refresh', res.data.data);
        }
      });
    };

    let updateData = toRefs(props).updateData;
    watch(
      updateData,
      (val) => {
        if (Object.keys(val).length > 0) expo.form.value = val;
      },
      { deep: true }
    );

    return { ...expo };
  },
  // data: () => ({
  //   AnasayfaStatu: [
  //     {
  //       value: false,
  //       title: 'Hayır',
  //     },
  //     {
  //       value: true,
  //       title: 'Evet',
  //     },
  //   ],
  //   form: {
  //     _id: null,
  //     baslik: null,
  //     gorsel: null,
  //     anasayfa: false,
  //     statu: true,
  //   },
  // }),
  // methods: {
  //   getValidationState({ dirty, validated, valid = null }) {
  //     return dirty || validated ? valid : null;
  //   },
  //   resetForm() {
  //     this.form = {
  //       _id: null,
  //       baslik: null,
  //       anasayfa: false,
  //       statu: true,
  //     };
  //     this.$emit('showLoading', false);
  //     this.$nextTick(() => {
  //       this.$refs.observer.reset();
  //     });
  //     this.$refs.baslik.focus();
  //   },

  //   onSubmit() {
  //     this.$emit('showLoading', true);
  //     const basrUrl = this.form._id == null ? 'bolgeEkle' : 'bolgeGuncelle';

  //     this.$store
  //       .dispatch(basrUrl, this.form)
  //       .then((res) => {
  //         if (res.data.success === true) {
  //           this.$toast({
  //             component: ToastificationContent,
  //             props: {
  //               title: `Bilgi`,
  //               icon: 'CheckSquareIcon',
  //               variant: 'success',
  //               text: this.form._id == null ? 'Ekleme Başarılı.' : 'Güncelleme Başarılı',
  //             },
  //           });
  //           this.$emit('refresh', res.data.data);
  //           this.resetForm();
  //         }
  //       })
  //       .catch((err) => {
  //         if (err.success === false) {
  //           this.$toast({
  //             component: ToastificationContent,
  //             props: {
  //               title: `Hata`,
  //               icon: 'CheckSquareIcon',
  //               variant: 'danger',
  //               text: err.message,
  //             },
  //           });
  //         }
  //       });
  //   },
  // },
  // watch: {
  //   updateData(val) {
  //     this.form._id = val._id;
  //     this.form.baslik = val.baslik;
  //     this.form.bolge_k_no = val.bolge_k_no;
  //     this.form.statu = val.statu;
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.grid {
  position: relative;
  overflow: hidden;
  max-height: 200px;
  .figcaption {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    text-align: center;
    color: #ffffff;
    padding: 20px;
    width: 100%;
    left: 0;
    right: 0;
  }
  &:hover {
    position: relative;
    background-color: rgba($color: #000000, $alpha: 1);
    img {
      opacity: 0.4;
    }
    .figcaption {
      opacity: 9;
    }
  }
}
</style>
